import L from 'leaflet';

const mapID = 'aidClinicMap';
const themeURL = document.getElementById(mapID).getAttribute('data-url');

const mapIcon = L.icon({
    iconUrl: `${themeURL}/dist/img/map-pin.svg`,
});
var map = L.map(mapID).setView([52.6135413, 16.5934175], 16);

L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    maxZoom: 19,
}).addTo(map);

L.marker([52.61355121610702, 16.595990491787312], {
    icon: mapIcon,
})
    .addTo(map)
    .bindPopup(
        `<div class="map-pin-img">
    <img src="${themeURL}/dist/img/map-pin-img.png" alt="AID Clinic Szamotuły"/>
    <p>
    AID Clinic Szamotuły <br>
    ul. Słoneczna 30 <br>
    64-500 Szamotuły
    </p>
    </div>`
    )
    .openPopup();
